import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { ProfileContext } from "../../../Context";
import { ApiConfig } from "../../../Api_Module/Api_Config/ApiEndpoints";
import { alertSuccessMessage } from "../../../UtilityComponent/CustomAlertMessage";
import copy from "copy-to-clipboard";

const AccountPage = () => {
  const [activeTab, setActiveTab] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let URL = window.location.href?.split('/');
    let route = URL.pop();
    if (URL?.length < 4 || !route) {
      navigate('/profile/Settings')
    }
  }, []);

  // ********* Context ********** //
  const { firstName, email, lastName, userImage, kycStatus, handleUserDetials, UIN } = useContext(ProfileContext);


  useEffect(() => {
    handleUserDetials();
  }, []);

  return (
    <>


      <div class="profile_sections">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h2 class="mb-0 pb-0">Account Settings</h2></div>
            <div class="col-md-6">
              <nav class=" ">
                <ol class="breadcrumb justify-content-end mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Account Settings
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>


      <section className="per_ac_settings  ">
        <div className=" container-custom">
          <form>
            {/* <div className="create-item-wrapper my_acc "> */}
            <div className="row">
              <div className="col-lg-3 col-md-12 col-md-4">
                <div className=" d-flex align-items-center ">
                  <img className="img-account-profile rounded-circle me-3" alt="UserImage"
                    src={userImage ? `${ApiConfig.baseUrl + userImage}` : "/images/profilelogo.png"}
                  />
                  <div>
                    <h5 className="fw-bolder pp-name fs-4 mb-0">
                      {`${firstName + ' ' + lastName}`}
                      <small className="text-success ms-2">
                        {kycStatus === 2 &&
                          <i className="ri-checkbox-circle-line"></i>
                        }
                      </small>
                    </h5>
                    <div className=" text-gray">
                      <small>{email && email}</small>
                    </div>
                    {/* <div className=" text-gray">
                        <small> UIN : {UIN}</small><span className="mx-2"><i className="ri-file-copy-line" style={{ cursor: 'pointer' }} onClick={() => { copy(UIN); alertSuccessMessage('Copied!!') }}></i></span>
                      </div> */}
                  </div>
                </div>
                <div className="acc_tabs">
                  <h6 class="acc_title">Account Settings</h6>

                  <ul className="nav nav-pills flex-column" id="cardTab" role="tablist">
                    <li  >
                      <Link to="Settings" className={`menu-link nav-link ${activeTab === 'Settings' ? 'active' : ' '}`}>
                        <i class="ri-user-line me-3"></i>
                        <span className="menu-title"> My Profile </span>
                      </Link>
                    </li>

                    <li >
                      <Link to="Twofactor" className={`menu-link nav-link ${activeTab === 'Twofactor' ? 'active' : ' '}`}>
                        <i class="ri-shield-user-line me-3 "></i>
                        <span className="menu-title"> Two Factor Authentication</span></Link>
                    </li>
                    <li className="active"  >
                      <Link className="menu-link  nav-link" id="kyc" to="/KycPage">
                        <i class="ri-user-follow-fill me-3"></i>
                        <span className="menu-title"> KYC Verification</span>
                      </Link>
                    </li>
            
                    <li >
                      <Link to="CurrencyPrefrence" className={`menu-link nav-link ${activeTab === 'CurrencyPrefrence' ? 'active' : ' '}`}>
                        <i class="ri-refund-line me-3"></i>
                        <span className="menu-title"> Currency Preference
                        </span></Link>
                    </li>
                    <li >
                      <Link to="BankAccount" className={`menu-link nav-link ${activeTab === 'BankAccount' ? 'active' : ' '}`}>
                        <i class="ri-bank-line me-3"></i>
                        <span className="menu-title"> Bank Account
                        </span></Link>
                    </li>

                    <li >
                      <Link to="Notifications" className={`menu-link nav-link ${activeTab === 'Notifications' ? 'active' : ' '}`}>
                        <i class="ri-notification-2-fill me-3"></i>
                        <span className="menu-title">Notification</span></Link>
                    </li>

                    <li >
                      <Link to="SecurityPage" className={`menu-link nav-link ${activeTab === 'SecurityPage' ? 'active' : ' '}`}>
                      <i class="ri-git-repository-private-fill  me-3"></i>
                        <span className="menu-title">Security</span></Link>
                    </li>
                    <li >
                      <Link to="Activitylog" className={`menu-link nav-link ${activeTab === 'Activitylog' ? 'active' : ' '}`}>
                        <i class="ri-file-list-3-line me-3"></i>
                        <span className="menu-title"> Activity Logs</span></Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <Outlet context={[setActiveTab]} />
              </div>
            </div>
            {/* </div> */}
          </form>
        </div>
      </section>
    </>
  );
};

export default AccountPage;
